import React from 'react'
import ReactGA from 'react-ga'

export const Footer: React.FC = () => {

  const onSocialMediaClick = (socialMedia: string) => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: socialMedia
    })
  }

  const onHomeClicked = () => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: 'Home'
    })
  }

  const onCookiePolicyClicked = () => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: 'Cookie policy'
    })
  }

  const onAllergiesClicked = () => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: 'Allergies'
    })
  }

  return <div className="App-footer">
    <div className={'social-media'}>
      <a
        href={'https://www.facebook.com/barvietnam'}
        target={'_blank'}
        rel="noopener noreferrer"
        onClick={() => onSocialMediaClick('Facebook')}>
        <img className={'social facebook-logo'} src={'assets/facebook-icon.png'}
             alt={'Bar Restaurante Vietnam Facebook link'}/>
        @barvietnam
      </a>
      <a
        href={'https://www.instagram.com/vietnamtf'}
        target={'_blank'}
        rel="noopener noreferrer"
        onClick={() => onSocialMediaClick('Instagram')}>
        <img className={'social instagram-logo'} src={'assets/instagram-icon.png'}
             alt={'Bar Restaurante Vietnam Instagram link'}/>
        @vietnamtf
      </a>
    </div>
    <div className={'address'}>
      <span>Rambla de Pulido 64, Santa Cruz de Tenerife, 38004</span>
    </div>
    <div className={'links'}>
      <a href={'/'}
         onClick={() => onHomeClicked()}>Inicio</a>
      <div className={'divider'}/>
      <a href={'/politica-de-cookies'}
         target={'_blank'}
         rel="noopener noreferrer"
         onClick={() => onCookiePolicyClicked()}>Política de cookies</a>
      <div className={'divider'}/>
      <a href={'/alergenos'}
         target={'_blank'}
         rel="noopener noreferrer"
         onClick={() => onAllergiesClicked()}>Alérgenos</a>
      <div className={'divider'}/>
      <span className={'developed-by'}>
        Restaurante Vietnam © 2023
      </span>
    </div>
  </div>
}