import React from 'react'
import ReactGA from 'react-ga'

export const Header: React.FC = () => {
  const onCallClicked = (telephone: string) => {
    ReactGA.event({
      category: 'Header',
      action: 'Call',
      label: `number ${telephone}`
    })
  }

  return <div className="App-header">
    <h1>
      <strong>Disponemos de servicio a domicilio:</strong>
    </h1>
    <img
      className={'phone-icon'}
      src="assets/phone-icon.png"
      alt="Phone icon"
    />
    <a href="tel:+34922288607" onClick={() => onCallClicked("922 28 86 07")}><span>922 28 86 07</span></a>
  </div>
}