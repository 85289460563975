import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { BrowserRouter, Route, Routes, } from "react-router-dom"
import { NotFound } from './NotFound'
import CookieConsent from 'react-cookie-consent'
import { Cookies } from './Cookies'
import ReactGA from 'react-ga'
import { isLocalhost } from '../serviceWorker'
import { Allergies } from './Allergies'
import { Home } from './Home'

const App: React.FC = () => {
  ReactGA.initialize('UA-164641230-1', { debug: isLocalhost })
  ReactGA.pageview(window.location.pathname + window.location.search)

  const onCookiePolicyClicked = () => {
    ReactGA.event({
      category: 'Cookies',
      action: 'Click',
      label: 'Link from banner'
    })
  }

  return (
    <div className="App">
      <CookieConsent
        buttonText={'Lo entiendo'}
        acceptOnScroll={true}>
        Esta web usa cookies para mejorar la experiencia del usuario. <a href={'/politica-de-cookies'}
                                                                         target={'_blank'}
                                                                         rel="noopener noreferrer"
                                                                         onClick={() => onCookiePolicyClicked()}>Click
        aquí</a> si quieres leer más sobre las políticas de cookies.
      </CookieConsent>
      <Header/>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<Home/>}/>
          <Route path={'/politica-de-cookies'} element={<Cookies/>}/>
          <Route path={'/alergenos'} element={<Allergies/>}/>
          <Route element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </div>
  )
}

export default App
