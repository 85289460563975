import React from 'react'
import ReactGA from 'react-ga'

export const Allergies: React.FC = () => {
  ReactGA.pageview(window.location.pathname + window.location.search)

  return <div className={'Allergies'}>
    <div className={'content'}>
      <h1>Alérgenos</h1>
      <p>
        En este establecimiento se elaboran alimentos que contienen sustancias
        susceptibles de causar alergias o intolerancia según lo recogido en el anexo II del
        reglamento EU nº 1169/2011, por lo que no puede garantizarse la inexistencia de
        ellas en nuestros platos y comidas.
      </p>
      <p>
        Por favor, si necesita una dieta especial, háganoslo saber, le ofreceremos productos
        adecuados a sus necesidades.
      </p>
      <img src={'assets/alergenos.png'} alt={'Listado de alérgenos'}/>
    </div>
  </div>
}