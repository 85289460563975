import React from 'react'
import { Button } from 'react-bootstrap'
import ReactGA from 'react-ga'
import { Link } from 'react-router-dom'

export const Home: React.FC = () => {
  const onViewMenuClicked = () => {
    ReactGA.event({
      category: 'Menu',
      action: 'Go',
      label: 'To Numier menu'
    })
  }

  return <div className={'Home'}>
    <div className={'logo'}>
      <img src="/assets/logo-bar-vietnam.png" />
      <img src="/assets/logo-restaurante-vietnam.png" />
    </div>
    <div className={'content'}>
      <div className={'links-container'}>
        <Link
          to={'https://www.numier.com/cartadigital/#/es/restaurantevietnam'}
          onClick={() => onViewMenuClicked()}
        >
          <Button variant="info">
            <span>Ver Menu</span>
          </Button>
        </Link>
      </div>
    </div>
    <hr />
    <div className={'horario'}>
        <p className={'horario-title'}><strong>Horario</strong></p>
        <p><i>Martes descanso</i></p>
        <p><strong>12:30 a 16:30</strong> y <strong>19:30 a 24:00</strong></p>
        <p><strong>Viernes y Sábado hasta la 1.00am</strong></p>
    </div>
    <hr />
    <div>
        <p className={'deliveries'}>Disponible en Glovo y Uber Eats</p>
    </div>
  </div>
}