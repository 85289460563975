import React from 'react'
import { Table } from 'react-bootstrap'
import ReactGA from 'react-ga'

export const Cookies: React.FC = () => {
  ReactGA.pageview(window.location.pathname + window.location.search)

  return <div className={'Cookies'}>
    <div className={'Content'}>
      <h2>Política de Cookies</h2>
      <h3>ANTECEDENTES</h3>

      <a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
      href={'www.restaurantevietnam.es'}>www.barvietnam.es</a> usan cookies. Las cookies de este sitio web se usan para
      personalizar
      el contenido y los anuncios, ofrecer funciones de redes sociales y analizar el tráfico. Además, compartimos
      información sobre el uso
      que haga del sitio web con nuestros partners de redes sociales, publicidad y análisis web, quienes pueden
      combinarla con otra información que les haya proporcionado o que hayan recopilado a partir del uso que haya hecho
      de sus servicios. Usted acepta nuestras cookies si continúa utilizando nuestro sitio web.

      <h3>¿QUÉ SON LAS COOKIES?</h3>

      Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies
      permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de
      un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo,
      pueden utilizarse para reconocer al usuario. Las cookies se asocian únicamente a un usuario anónimo y su ordenador
      ó dispositivo y no proporcionan referencias que permitan conocer sus datos personales.

      <h3>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXENTAS</h3>

      Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las
      cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las
      necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente demandados por el
      usuario.

      <h3>TIPOS DE COOKIES</h3>

      <p>a) Según la entidad que las gestione, hay Cookies propias (aquéllas que se envían al equipo terminal del
        usuario
        desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio Solicitado por el
        usuario) y de terceros (las que se envían al equipo terminal del usuario desde un equipo o dominio que no es
        gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies).</p>

      <p>b) Según el tiempo que permanecen activas, existen las de sesión (diseñadas para recabar y almacenar datos
        mientras el usuario accede a una página web) y las persistentes (en las que los datos se almacenan en el
        terminal
        y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie -puede ir de unos
        minutos a varios años-).</p>

      <p>c) Según la finalidad para la que traten la información que recopilan, pueden ser:

        <ul>– Cookies técnicas (necesarias para el uso de la web y la prestación del servicio contratado),</ul>

        <ul>– Cookies de personalización (que permiten al usuario acceder al servicio con características predefinidas,
          como
          por ejemplo el idioma, tipo de navegador, configuración regional, etc.)
        </ul>

        <ul>– Cookies de análisis (recogen información el uso que se realiza de la web),</ul>

        <ul>– Cookies publicitarias (recogen información sobre las preferencias y elecciones personales de los
          usuarios),
        </ul>

        <ul>– Cookies de afiliados (permiten realizar un seguimiento de las visitas procedentes de otras webs, con las
          que el
          sitio web establece un contrato de afiliación).
        </ul>
      </p>

      <h3>TIPOS DE COOKIES UTILIZADAS POR ESTE SITIO WEB</h3>

      <p>Las cookies utilizadas en <a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
        href={'www.restaurantevietnam.es'}>www.barvietnam.es</a> son de sesión y de terceros, y nos
        permiten
        almacenar y acceder a información relativa al idioma, el tipo de navegador utilizado, y otras características
        generales predefinidas por el usuario, así como seguir y analizar la actividad que lleva a cabo para mejorar y
        prestar nuestros servicios de una manera más eficiente y personalizada. Las cookies usadas tienen, en todo caso,
        carácter temporal, con la única finalidad de hacer más eficaz la navegación. En ningún caso estas cookies
        proporcionan por sí mismas datos de carácter personal y no se usan para la recogida de los mismos.
      </p>

      La utilización de las cookies ofrece ventajas, como por ejemplo:
      <p>
        facilita al usuario la navegación y el acceso a los diferentes servicios que ofrece este sitio web;
        evita al usuario tener que configurar las características generales predefinidas cada vez que accede al sitio
        web;
      </p>
      y
      <p>
        favorece la mejora del funcionamiento y de los servicios prestados a través de este sitio web, tras el
        correspondiente análisis de la información obtenida a través de las cookies instaladas.
        Al acceder a <a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
        href={'www.restaurantevietnam.es'}>www.barvietnam.es</a> se le informa que si sigue navegando se le instalarán
        diversas cookies de terceros consintiendo así la instalación de determinadas cookies que tendrán como única
        finalidad la de registrar el acceso a nuestro sitio web para la realización de estadísticas anónimas sobre las
        visitas, recopilando información siempre de forma anónima. No es necesario que acepte la instalación de estas
        cookies, podrá navegar igualmente por toda nuestra web.
      </p>

      En diversas secciones de nuestra web se podrán instalar cookies de redes sociales, en concreto las siguientes:
      <ul>Cookie de Twitter, según lo dispuesto en su política de privacidad y uso de cookies.</ul>
      <ul>Cookie de Facebook, según lo dispuesto en su política de cookies.</ul>
      <ul>Cookie de Linkedin, según lo dispuesto en su página sobre el uso de las cookies.</ul>
      <ul>Cookie de Google+ y Google Maps, según lo dispuesto en su página sobre qué tipo de cookies utilizan.</ul>

      <h4>Categoría: Estadística (3)</h4>

      <Table>
        <thead>
        <tr>
          <th>Nombre</th>
          <th>Proveedor</th>
          <th>Tipo</th>
          <th>Caducidad</th>
          <th>Finalidad</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>_ga</td>
          <td><a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
            href={'www.restaurantevietnam.es'}>www.barvietnam.es</a></td>
          <td>HTTP</td>
          <td>2 años</td>
          <td>Registra una identificación única que se utiliza para generar datos estadísticos
            acerca de cómo utiliza el visitante el sitio web.
          </td>
        </tr>
        <tr>
          <td>_gat</td>
          <td><a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
            href={'www.restaurantevietnam.es'}>www.barvietnam.es</a></td>
          <td>HTTP</td>
          1 día
          <td>Utilizado por Google Analytics para controlar la tasa de peticiones</td>
        </tr>
        <tr>
          <td>_gid</td>
          <td><a href={'www.restaurantevietnam.es'}>www.restaurantevietnam.es</a> y <a
            href={'www.restaurantevietnam.es'}>www.barvietnam.es</a></td>
          <td>HTTP</td>
          <td>1 día</td>
          <td>Registra una identificación única que se utiliza para generar datos estadísticos
            acerca de cómo utiliza el visitante el sitio web.
          </td>
        </tr>
        </tbody>
      </Table>

      <h4>Categoría: Marketing (1)</h4>

      <Table>
        <thead>
        <tr>
          <th>Nombre</th>
          <th>Proveedor</th>
          <th>Tipo</th>
          <th>Caducidad</th>
          <th>Finalidad</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>r/collect</td>
          <td>doubleclick.net</td>
          <td>Pixel</td>
          <td>Session</td>
          <td>Esta cookie se utiliza para enviar datos a Google Analytics sobre el dispositivo y comportamiento del
            visitante. Hace un seguimiento del visitante en todos los dispositivos y canales de marketing.
          </td>
        </tr>
        </tbody>
      </Table>

      <h3>REVOCACIÓN</h3>

      <p>En todo momento podrá acceder a la configuración de su navegador aceptando o rechazando todas las cookies, o
        bien seleccionar aquéllas cuya instalación admite y cuáles no, siguiendo uno de los siguientes procedimientos,
        que depende del navegador que utilice:</p>
      <br/>
      <h4>Google Chrome (en el Menú Herramientas):</h4>
      Configuración &gt; Mostrar opciones avanzadas &gt; Privacidad (Configuración de contenido) &gt; Cookies:
      <br/>
      <a href={'https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es'}
         target={'_blank'}
         rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=es</a>

      <h4>Microsoft Internet Explorer (en el Menú Herramientas):</h4>
      Opciones de Internet &gt; Privacidad &gt; Avanzada:
      <br/>
      <a href={'https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies'}
         target={'_blank'}
         rel="noopener noreferrer">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>

      <h4>Firefox:</h4>
      Opciones &gt; Privacidad &gt; Cookies:
      <br/>
      <a href={'https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias'}
         target={'_blank'}
         rel="noopener noreferrer">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a>

      <h4>Safari, iPad y iPhone:</h4>
      <br/>
      Preferencias &gt; Privacidad
      <br/>
      <a href={'https://support.apple.com/kb/ph21411?locale=es_ES'}
         target={'_blank'}
         rel="noopener noreferrer">https://support.apple.com/kb/ph21411?locale=es_ES</a>

      <h4>Opera:</h4>
      Configuración &gt; Opciones &gt; Avanzado &gt; Cookies
      <br/>
      <a href={'http://help.opera.com/Windows/12.00/es-ES/cookies.html'}
         target={'_blank'}
         rel="noopener noreferrer">http://help.opera.com/Windows/12.00/es-ES/cookies.html</a>

      <p>Estos navegadores están sometidos a actualizaciones o modificaciones, por lo que no podemos garantizar que se
        ajusten completamente a la versión de su navegador. También puede ser que utilice otro navegador no contemplado
        en estos enlaces como Konqueror, Arora, Flock, etc. Para evitar estos desajustes, puede acceder directamente
        desde
        las opciones de su navegador, generalmente en el menú de “Opciones” en la sección de “Privacidad”. (Por favor,
        consulte la ayuda de su navegador para más información).</p>

      <h3>DESACTIVACIÓN/ACTIVACIÓN Y ELIMINACIÓN DE COOKIES</h3>

      Para restringir o bloquear las cookies, se hace a través de la configuración del navegador.
      <br/>
      <p>Si no desea que los sitios web pongan ninguna cookie en su equipo, puede adaptar la configuración del navegador
        de modo que se le notifique antes de que se coloque ninguna cookie. De igual modo, puede adaptar la
        configuración de forma que el navegador rechace todas las cookies, o únicamente las cookies de terceros. También
        puede eliminar cualquiera de las cookies que ya se encuentren en el equipo. Tenga en cuenta que tendrá que
        adaptar por separado la configuración de cada navegador y equipo que utilice.</p>

      <p>Tenga en cuenta que si no desea recibir cookies, ya no podremos garantizar que nuestro sitio web funcione
        debidamente. Puede que algunas funciones del sitio se pierdan y es posible que ya no pueda ver ciertos sitios
        web.
        Además, rechazar las cookies no significa que ya no vaya a ver anuncios publicitarios. Simplemente los anuncios
        no
        se ajustarán a sus intereses y se repetirán con más frecuencia.</p>

      <p>Cada navegador posee un método distinto para adaptar la configuración. Si fuera necesario, consulte la función
        de
        ayuda del navegador para establecer la configuración correcta.</p>

      <p>Para desactivar las cookies en el teléfono móvil, consulte el manual del dispositivo para obtener más
      información.</p>

      Puede obtener más información sobre las cookies en Internet, <a href={'http://www.aboutcookies.org/'}
                                                                      target={'_blank'}
                                                                      rel="noopener noreferrer">http://www.aboutcookies.org/</a>.

      <p>Teniendo en cuenta la forma en la que funciona Internet y los sitios web, no siempre contamos con información
        de
        las cookies que colocan terceras partes a través de nuestro sitio web. Esto se aplica especialmente a casos en
        los
        que nuestra página web contiene lo que se denominan elementos integrados: textos, documentos, imágenes o breves
        películas que se almacenan en otra parte, pero se muestran en nuestro sitio web o a través del mismo.</p>

      <p>Por consiguiente, en caso de que se encuentre con este tipo de cookies en este sitio web y no estén enumeradas
        en
        la lista anterior, le rogamos que nos lo comunique. O bien póngase en contacto directamente con el tercero para
        pedirle información sobre las cookies que coloca, la finalidad y la duración de la cookie, y cómo ha garantizado
        su privacidad.</p>
    </div>
  </div>
}